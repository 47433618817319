<template>
    <div>
        <h2> 404 : Page not found </h2>
    </div>
</template>
<script>

export default {
    name: 'not-found',
}
</script>

<style lang="scss" scoped>
div {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    a {
        font-size: 2rem;;
    }
}
</style>
