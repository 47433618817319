import Vue from 'vue'
import App from './App.vue'
import router from './router.js'

Vue.config.productionTip = false;

import './style/scss/global.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet-basemaps/L.Control.Basemaps.css';
import 'leaflet-minimap/dist/Control.MiniMap.min.css'

// register global function for plausible events
if (window.plausible) {
    Vue.prototype.$plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
}
else Vue.prototype.$plausible = function() {};

const svgTemplate = require.context('@/assets/img/icons/inline/.?vue-template', false, /\.svg$/);
svgTemplate.keys().map(async key => {
    const name = key.match(/\w+/)[0]; // remove extension
    const withRender = svgTemplate(key);
    Vue.component('icon-' + name, {
        functional: true,
        render: function (createElement, ctx) {
            const { width, height, color } = ctx.props;
            return createElement(withRender({}), 
            {
                style: {
                    width: width,
                    height: height ? height : width,
                    fill: color
                },
                class: 'svg-icon'
            })
        },
        props: {
            width: {
              type: String,
              default: '1rem'
            },
            height: {
                type: String,
                default: null
            },
            color: {
                type: String,
                default: 'currentColor'
            }
        }
    });
});


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')