import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '@/views/NotFound.vue';

Vue.use(VueRouter);
const config =  require('@/config.json');
Vue.prototype.$config = config;
document.title = config["platform_name"];
const routes = [
    {
        path: '/',
        component: () => import('@/views/Map.vue'),
        props: { countryCode: "sm"},
    }
];

// non-matching routes all point to 404
routes.push({
    path: '*',
    component: NotFound,
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


Vue.config.productionTip = false;

export default router
